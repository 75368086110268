import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "primereact/button";

import "./LogoutButton.css";

const LogoutButton = () => {
  const { logout } = useAuth0();

  const returnToUrl =
    process.env.NODE_ENV === "production"
      ? "https://main.d2mpr4bnl4k2sw.amplifyapp.com/"
      : window.location.origin;

  return (
    <div className="logout-container">
      <Button
        label="Log Out"
        className="p-button-rounded p-button-danger"
        onClick={() => logout({ returnTo: returnToUrl })}
      />
    </div>
  );
};

export default LogoutButton;
