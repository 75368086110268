import { jwtDecode } from "jwt-decode";

export async function checkAdminPermissions(getAccessTokenSilently) {
  try {
    const token = await getAccessTokenSilently();
    const decoded = jwtDecode(token);
    return decoded.permissions && decoded.permissions.includes("admin");
  } catch (error) {
    console.error("Error decoding token or checking permissions:", error);
    return false;
  }
}
