import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";

import "./TransactionList.css";
import useTransactions from "../../../hooks/useTransactions";
import TransactionItem from "./components/TransactionItem";
import PaginationControls from "./components/PaginationControls";
import TotalCharges from "./components/TotalCharges";
import SearchBar from "./components/SearchBar";

const TransactionList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);

  const { id } = useParams();
  const stationId = id;

  const { transactions, setTransactions, loading } = useTransactions(
    stationId,
    currentYear,
    currentMonth
  );

  const today = new Date();
  const isCurrentMonth =
    currentYear === today.getFullYear() &&
    currentMonth === today.getMonth() + 1;

  const goToNextMonth = () => {
    if (!isCurrentMonth && !loading) {
      if (currentMonth === 12) {
        setCurrentMonth(1);
        setCurrentYear(currentYear + 1);
      } else {
        setCurrentMonth(currentMonth + 1);
      }
    }
  };

  const goToPreviousMonth = () => {
    if (!loading) {
      if (currentMonth === 1) {
        setCurrentMonth(12);
        setCurrentYear(currentYear - 1);
      } else {
        setCurrentMonth(currentMonth - 1);
      }
    }
  };

  const clearTransactions = () => {
    setTransactions([]);
  };

  const filteredTransactions = useMemo(() => {
    return transactions?.filter((transaction) =>
      transaction?.transactionId
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }, [transactions, searchTerm]);

  const totalCharged = useMemo(() => {
    if (!filteredTransactions || filteredTransactions?.length === 0) {
      return "0.00";
    }

    return filteredTransactions
      .reduce((total, transaction) => {
        if (
          transaction?.chargedAmount !== "Free" &&
          transaction?.chargedAmount
        ) {
          const amount = parseFloat(
            transaction.chargedAmount.replace("€", "").replace(",", ".")
          );
          return total + amount;
        }
        return total;
      }, 0)
      .toFixed(2);
  }, [filteredTransactions]);

  return (
    <div className="transaction-list-container">
      <h2 className="transaction-header">Transaction List</h2>
      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        className="search-bar-container"
      />

      <div className="transaction-list">
        {loading ? (
          <p>Loading transactions...</p>
        ) : filteredTransactions?.length === 0 ? (
          <p>No transactions found for this month.</p>
        ) : (
          filteredTransactions?.map((transaction) => (
            <TransactionItem
              key={transaction?.id}
              transaction={transaction}
              className="transaction-item"
            />
          ))
        )}
      </div>
      <PaginationControls
        currentYear={currentYear}
        currentMonth={currentMonth}
        goToPreviousMonth={goToPreviousMonth}
        goToNextMonth={goToNextMonth}
        isCurrentMonth={isCurrentMonth}
        className="pagination-controls"
        loading={loading}
      />
      <TotalCharges
        totalCharged={totalCharged}
        totalTransactions={filteredTransactions?.length}
        clearTransactions={clearTransactions}
        className="total-charges-container"
      />
    </div>
  );
};

export default TransactionList;
