export const downloadQrCode = (id) => {
  const canvas = document.getElementById("qrcode");
  if (canvas) {
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = `${id}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(url);
    }, "image/png");
  } else {
    console.error("QR code canvas element not found.");
  }
};
