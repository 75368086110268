import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { PrimeReactProvider } from "primereact/api";
import { ProgressSpinner } from "primereact/progressspinner";

import Home from "./pages/Home";
import LoginButton from "./pages/Login/Login";
import LogoutButton from "./pages/LogOut/LogOut";
import AddOwner from "./pages/StationManagment/AddOwners/AddOwners";
import Stations from "./pages/StationManagment/StationList/StationList";
import StationDetails from "./pages/StationManagment/StationDetails/StationDetails";
import QrCode from "./pages/StationManagment/QrCode/QrCodeManagment";
import AddBankAccountDetails from "./pages/PaymentMethod/AddBankAccountDetails";
import Transactions from "./pages/StationManagment/Transactions/TransactionList";
import "./App.css";
import MenuBarNavigation from "./navigation/MenuBarNavigation";
import { checkAdminPermissions } from "./utils/checkAdminPermission";

function App() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [isAuthenticatedState, setIsAuthenticatedState] = useState(false);

  useEffect(() => {
    const verifyAdmin = async () => {
      if (isAuthenticated) {
        const hasAdminAccess = await checkAdminPermissions(
          getAccessTokenSilently
        );
        setIsAuthenticatedState(hasAdminAccess);
      } else {
        setIsAuthenticatedState(false);
      }
    };

    verifyAdmin();
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <ProgressSpinner />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <PrimeReactProvider>
      <Router>
        <div className="app-container">
          {isAuthenticatedState && <MenuBarNavigation />}
          <div className="main-content">
            <Routes>
              {isAuthenticatedState ? (
                <>
                  <Route path="/" element={<Home />} />
                  <Route path="/logout" element={<LogoutButton />} />
                  <Route path="/add-owner/:id" element={<AddOwner />} />
                  <Route path="/station-list" element={<Stations />} />
                  <Route path="/qrcode-managment/:id" element={<QrCode />} />
                  <Route
                    path="/add-bank-account-details/:id"
                    element={<AddBankAccountDetails />}
                  />
                  <Route
                    path="/station-details/:id"
                    element={<StationDetails />}
                  />
                  <Route path="/transactions/:id" element={<Transactions />} />
                </>
              ) : (
                <Route path="/*" element={<LoginButton />} />
              )}
            </Routes>
          </div>
        </div>
      </Router>
    </PrimeReactProvider>
  );
}

export default App;
