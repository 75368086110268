export const validateName = (value) => {
  const namePattern = /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
  if (!namePattern.test(value)) {
    return "Please enter your full name (first and last name).";
  }
  return null;
};

export const validateEmail = (value) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(value)) {
    return "Please enter a valid email address.";
  }
  return null;
};

export const validateIban = (value) => {
  const ibanPattern = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
  if (!ibanPattern.test(value)) {
    return "Please enter a valid IBAN.";
  }
  return null;
};
