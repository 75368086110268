import { clearingApi } from "../config/apiConfig";

export const getTransactions = async (id, year, month) => {
  try {
    const response = await clearingApi.get(
      `/cdrs/?year=${year}&month=${month}&station_id=${id} `
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
