import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import QRCode from "qrcode.react";

import { downloadQrCode } from "../../../utils/qrCode";
import "./QrCodeManagement.css";
import { getStationDetails } from "../../../services/stations";
import { ProgressSpinner } from "primereact/progressspinner";

export default function QrCodeManagment() {
  const { id } = useParams();
  const [qrCodeValue, setQrCodeValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const generateQrCode = (evse_id) => {
    setQrCodeValue(`https://direct.cocharge.app/${evse_id}`);
  };
  const [stationDetails, setStationDetails] = useState({});
  const loadStationDetails = async (id) => {
    setIsLoading(true);
    try {
      const res = await getStationDetails(id);
      setStationDetails(res);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadStationDetails(id);
  }, [id]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <ProgressSpinner />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="container">
      <Card title={`QrCode Management`}>
        {(stationDetails.evses || []).map((eve) => (
          <div>
            <h4>Connector id: {eve.evse_id}</h4>
            <div className="qr-container">
              <Button
                label="Generate QR Code"
                onClick={() => generateQrCode(eve.evse_id)}
                className="qr-button"
              />
              <Button
                label="Download QR Code"
                onClick={() => downloadQrCode(eve.evse_id)}
                className="qr-button-download"
              />
            </div>
          </div>
        ))}
        {qrCodeValue && (
          <div className="qr-code">
            <QRCode id="qrcode" value={qrCodeValue} size={256} />
          </div>
        )}
      </Card>
    </div>
  );
}
