import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

const TransactionItem = ({ transaction }) => {
  return (
    <Card key={transaction?.id} className="transaction-card">
      <div className="transaction-details">
        <div className="transaction-info">
          <h5 className="transaction-id">
            Transaction ID: {transaction?.transactionId}
          </h5>
          <p className="transaction-date">{transaction?.date}</p>
          <div className="transaction-stats">
            <span>Duration: {transaction?.chargeDuration}</span>
            <span> | Energy: {transaction?.energy}</span>
            <span> | Station: {transaction?.chargingstationName}</span>
          </div>
        </div>
        <div className="transaction-amount">
          {transaction?.status === "Free" ? (
            <Button className="free-button" label="Free" />
          ) : (
            <span className="amount-label">{transaction?.chargedAmount}</span>
          )}
        </div>
      </div>
    </Card>
  );
};

export default TransactionItem;
