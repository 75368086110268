export const storeToken = (token) => {
  try {
    const jsonValue = JSON.stringify(token);
    localStorage.setItem("token", jsonValue);
  } catch (error) {
    console.log("Error storing the token", error);
  }
};

export const getToken = () => {
  try {
    const jsonValue = localStorage.getItem("token");
    if (jsonValue !== null) {
      return JSON.parse(jsonValue);
    }
  } catch (error) {
    console.log("Error getting the token", error);
  }
};
