import React from "react";
import { Divider } from "primereact/divider";

import "./EvseList.css";

export default function EvseList({ evses }) {
  if (!evses || evses.length === 0) {
    return <p>No evses available</p>;
  }

  return (
    <div className="evse-list">
      <h5 className="section-title">Evses:</h5>
      {evses.map((evse, index) => (
        <div key={evse.evse_id} className="evse-item">
          <h6>EVSE ID: {evse.evse_id}</h6>
          <h6>Status: {evse.status}</h6>
          <div className="connector-list">
            {evse.connectors.map((connector, idx) => (
              <div key={idx} className="connector-item">
                <h6>Connector {connector.id}:</h6>
                <h6>Type: {connector.power_type}</h6>
                <h6>Max Power: {connector.max_electric_power} kW</h6>
                <h6>Standard: {connector.standard}</h6>
              </div>
            ))}
          </div>
          <div className="capabilities-section">
            <h6>Capabilities:</h6>
            {evse.capabilities && evse.capabilities.length > 0 ? (
              <ul>
                {evse.capabilities.map((capability, idx) => (
                  <li key={idx}>{capability}</li>
                ))}
              </ul>
            ) : (
              <p>No capabilities listed</p>
            )}
          </div>
          {index < evses.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  );
}
