import React, { useState } from "react";

import CustomGoogleMap from "../../../../components/GoogleMap/GoogleMap";
import "./MapAndAddress.css";

export default function MapAndAddress({ coordinates, address, city, state }) {
  const [isMapExpanded, setIsMapExpanded] = useState(false);

  const toggleMapSize = () => {
    setIsMapExpanded(!isMapExpanded);
  };

  return (
    <div className="map-and-address">
      <div className="map-container-wrapper">
        <div
          className={`map-container ${isMapExpanded ? "expanded" : ""}`}
          onDoubleClick={toggleMapSize}
        >
          {coordinates && coordinates.latitude && coordinates.longitude ? (
            <CustomGoogleMap
              center={{
                lat: parseFloat(coordinates.latitude),
                lng: parseFloat(coordinates.longitude),
              }}
            />
          ) : (
            <p>Loading map...</p>
          )}
        </div>
        <p className="zoom-hint">
          Use <strong>Ctrl + Scroll</strong> to zoom in and out on the map.
        </p>
      </div>

      <div className="info-section">
        <h5 className="section-title">Address:</h5>
        <p>
          {address}, {city}, {state}
        </p>
      </div>
    </div>
  );
}
