import React from "react";
import { Button } from "primereact/button";

const TotalCharges = ({
  totalCharged,
  totalTransactions,
  clearTransactions,
}) => {
  return (
    <div className="total-charges">
      <span style={{ marginRight: "10px" }}>
        Total Charges: {totalTransactions} Charges €{totalCharged}
      </span>
      <Button
        label="Clear History"
        icon="pi pi-trash"
        className="clear-history"
        onClick={clearTransactions}
      />
    </div>
  );
};

export default TotalCharges;
