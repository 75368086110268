import React from "react";
import { Divider } from "primereact/divider";

import "./OpeningHours.css";

const getWeekdayName = (weekday) => {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return weekdays[weekday - 1];
};

export default function OpeningHours({ openingTimes }) {
  return (
    <div className="opening-times-section">
      <h5 className="section-title">Opening Hours:</h5>
      {openingTimes && openingTimes.regular_hours ? (
        <ul>
          {openingTimes.regular_hours.map((time, index) => (
            <li key={index}>
              {getWeekdayName(time.weekday)}: {time.period_begin} -{" "}
              {time.period_end}
            </li>
          ))}
        </ul>
      ) : (
        <p>Opening hours not available</p>
      )}
      <Divider />
    </div>
  );
}
