import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { ProgressSpinner } from "primereact/progressspinner";

import { getStationsApi } from "../../../services/stations";
import { getDropdownWidth } from "../../../utils/screenSize";
import "./StationList.css";

export default function StationList() {
  const [stations, setStations] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const navigate = useNavigate();

  const statuses = ["AVAILABLE", "CHARGING", "UNKNOWN"];

  useEffect(() => {
    getStationsApi()
      .then((data) => {
        const stationsWithStatus = data.map((station) => {
          const evseStatus =
            station.evses && station.evses.length > 0
              ? station.evses[0].status
              : "Unknown";
          return { ...station, status: evseStatus };
        });
        setStations(stationsWithStatus);
        setError(null);
      })
      .catch((err) => {
        console.error("Error fetching stations:", err);
        setError("Something went wrong, please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
  };

  const onRowSelect = (e) => {
    navigate(`/station-details/${e.data.id}`);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search hide-on-small" />
          <InputText
            value={filters.global.value || ""}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    let color = "gray";

    if (rowData.status === "AVAILABLE") {
      color = "green";
    } else if (rowData.status === "CHARGING") {
      color = "blue";
    } else if (rowData.status === "UNKNOWN") {
      color = "orange";
    }
    return rowData.status ? (
      <Tag
        value={rowData.status}
        style={{
          backgroundColor: color,
          color: "white",
          borderRadius: "4px",
          padding: "5px 10px",
        }}
      />
    ) : null;
  };

  const statusItemTemplate = (option) => {
    return <Tag value={option} />;
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => {
          options.filterApplyCallback(e.value);
        }}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter responsive-dropdown"
        showClear
        style={{ minWidth: getDropdownWidth() }}
      />
    );
  };

  const header = renderHeader();

  if (loading) {
    return (
      <div className="loading-container">
        <ProgressSpinner />
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      <DataTable
        value={stations}
        paginator
        rows={10}
        dataKey="id"
        filters={filters}
        filterDisplay="row"
        globalFilterFields={["id"]}
        header={header}
        emptyMessage="No stations found."
        selectionMode="single"
        selection={selectedStation}
        onSelectionChange={(e) => {
          setSelectedStation(e.value);
        }}
        onRowSelect={onRowSelect}
      >
        <Column
          field="id"
          header="Station ID"
          filter
          filterPlaceholder="Search by ID"
        />
        <Column
          field="status"
          header="Status"
          showFilterMenu={false}
          filterMenuStyle={{ width: "14rem" }}
          style={{ minWidth: "12rem" }}
          body={statusBodyTemplate}
          filter
          filterElement={statusRowFilterTemplate}
        />
      </DataTable>
    </div>
  );
}
