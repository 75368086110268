import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

import "./AddOwners.css";
import {
  getStationOwners,
  addStationOwner,
  deleteStationOwner,
  addStationOwnerPermission,
} from "../../../services/owners";

const AddOwner = () => {
  const { id } = useParams();
  const toast = useRef(null);

  const [owners, setOwners] = useState([]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getStationOwners(id)
      .then((data) => {
        console.log(data);
        setOwners(data);
        setError(null);
      })
      .catch((error) => {
        console.error("Error fetching owners:", error);
        setError("Something went wrong, please try again later.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const handleAddOwner = async () => {
    try {
      if (!id) {
        alert("Station ID is required to add an owner.");
        return;
      }
      setIsLoading(true);
      setError("");

      await addStationOwner(id, email);

      const updatedOwners = await getStationOwners(id);
      setOwners(updatedOwners);
      alert("Owner added successfully!");

      setEmail("");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Failed to add owner";
      setError(errorMessage);
      alert(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPermission = async () => {
    try {
      if (!id) {
        alert("Station ID is required to add an owner.");
        return;
      }
      setIsLoading(true);

      await addStationOwnerPermission(id);
      alert("Permission added successfully!");
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        if (status === 400) {
          alert("The permission already exists.");
        } else if (status === 401) {
          alert(
            "Unauthorized: You don't have permission to perform this action."
          );
        } else if (status === 403) {
          alert("Forbidden: You don't have access to this resource.");
        } else {
          alert("An unknown error occurred. Please try again.");
        }
      } else {
        alert("Network Error: Please check your connection and try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderDeleteBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-danger"
        tooltip="Delete"
        tooltipOptions={{ position: "top" }}
        onClick={() => {
          setIsLoading(true);
          deleteStationOwner(id, rowData.email)
            .then(() => {
              getStationOwners(id).then((data) => {
                setOwners(data);
              });
              setIsLoading(false);
            })
            .catch((error) => {
              setError(
                error?.response?.data?.message || "Failed to delete owner"
              );
              setIsLoading(false);
            });
        }}
      />
    );
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <ProgressSpinner />
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="container">
      <Toast ref={toast} />
      <div className="flex flex-column md:flex-row gap-3">
        <div className="p-inputgroup flex-1">
          <span className="p-inputgroup-addon icon">
            <i className="pi pi-at"></i>
          </span>
          <InputText
            placeholder="Add Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div>
        <Button
          label="Add Owner"
          className="p-button-raised add-owner-button button-width"
          onClick={handleAddOwner}
        />
        <Button
          label="Add Permission"
          className="p-button-raised add-owner-button button-width"
          onClick={handleAddPermission}
        />
      </div>
      {error && <div className="error-message">{error}</div>}
      <Divider />
      <DataTable value={owners} tableStyle={{ width: "100%" }}>
        <Column
          body={renderDeleteBodyTemplate}
          style={{ textAlign: "center", width: "4rem" }}
        />
        <Column field="email" header={`${id} Owners Email List`} />
      </DataTable>
    </div>
  );
};

export default AddOwner;
