export const getDropdownWidth = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth < 375) {
    return "4rem";
  } else if (screenWidth < 425) {
    return "6rem";
  } else if (screenWidth < 768) {
    return "8rem";
  } else if (screenWidth > 1024) {
    return "16rem";
  }

  return "12rem";
};
