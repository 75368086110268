import { useState, useEffect } from "react";
import { calculateChargeDuration } from "../utils/calculations";
import { formatDate } from "../utils/formats";
import { getTransactions } from "../services/transactions";

const useTransactions = (stationId, currentYear, currentMonth) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const data = await getTransactions(
          stationId,
          currentYear,
          currentMonth
        );

        if (!data || data?.length === 0) {
          setTransactions([]);
          return;
        }

        const mappedTransactions =
          data?.map((item) => ({
            id: item?.cdrId,
            transactionId: item?.cdrId,
            chargeDuration: calculateChargeDuration(
              item?.startDateTime,
              item?.endDateTime
            ),
            chargedAmount:
              item?.totalCost > 0 ? `€${item?.totalCost?.toFixed(2)}` : "Free",
            energy: item?.totalEnergy, // Now using totalEnergy directly
            date: formatDate(item?.startDateTime),
            status: item?.totalCost > 0 ? "Paid" : "Free",
            chargingstationName: item?.chargingstationName || "Unknown Station",
            energyCost: `€${item?.totalEnergyCost?.toFixed(2)}`, // Handling energy cost
            blockingCost: `€${item?.totalBlockingCost?.toFixed(2)}`, // Handling blocking cost
          })) || [];

        setTransactions(mappedTransactions);
      } catch (error) {
        console.error(error);
        setTransactions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [stationId, currentYear, currentMonth]);

  return { transactions, setTransactions, loading };
};

export default useTransactions;
