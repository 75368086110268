import { locationApi, csCommunicationApi } from "../config/apiConfig";

export const getStationsApi = async () => {
  try {
    const response = await locationApi.get();
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getStationDetails = async (id) => {
  try {
    const response = await csCommunicationApi.get(`locations/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
