export const formatDate = (dateTime) => {
  if (!dateTime) return "Invalid Date";

  const date = new Date(dateTime);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  return date.toLocaleString();
};
