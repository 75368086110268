export const calculateChargeDuration = (startDateTime, endDateTime) => {
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);
  const diffMs = end - start;
  const diffHrs = Math.floor(diffMs / 3600000);
  const diffMins = Math.floor((diffMs % 3600000) / 60000);
  if (diffHrs > 0) {
    return `${diffHrs}h ${diffMins}m`;
  } else {
    return `${diffMins}m`;
  }
};

export const calculateEnergy = (meterStart, meterEnd) => {
  const energyConsumed = meterEnd - meterStart;
  return `${energyConsumed.toFixed(2)} kWh`;
};
