import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "primereact/button";

import { storeToken } from "../../storage/AuthStorage";
import "./LoginButton.css";

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  useEffect(() => {
    const saveToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          storeToken(token);
        } catch (error) {
          console.error("Error fetching token:", error);
        }
      }
    };

    saveToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    <div className="login-container">
      <Button
        label="Log In"
        icon="pi pi-sign-in"
        className="p-button-rounded p-button-success"
        onClick={() => loginWithRedirect()}
      />
    </div>
  );
};

export default LoginButton;
