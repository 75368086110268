import React from "react";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";

export default function InputField({
  id,
  label,
  value,
  error,
  onChange,
  placeholder,
  icon,
}) {
  return (
    <div className="input-group-margin">
      <label htmlFor={id} className="input-label">
        {label}
      </label>
      <div className="p-inputgroup flex-1">
        <span className="p-inputgroup-addon">
          <i className={icon}></i>
        </span>
        <InputText
          id={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
      {error && (
        <div className="error-message-container">
          <Message severity="error" text={error} />
        </div>
      )}
    </div>
  );
}
