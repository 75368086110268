import { accountApi } from "../config/apiConfig";

export const addStationOwner = async (id, ownerEmail) => {
  try {
    const response = await accountApi.post("add-owner", {
      owner_email: ownerEmail,
      location_id: id,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addStationOwnerPermission = async (locationId) => {
  try {
    const response = await accountApi.post("add-permission", {
      location_id: locationId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteStationOwner = async (id, ownerEmail) => {
  try {
    const response = await accountApi.post("delete-owner", {
      owner_email: ownerEmail,
      location_id: id,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStationOwners = async (id) => {
  try {
    const response = await accountApi.get(`station-owners/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
