import { bankAccountApi } from "../config/apiConfig";

export const getBankAccountDetails = async (id) => {
  try {
    const response = await bankAccountApi.get(`bank-details/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const addBankAccountDetails = async (
  id,
  name,
  iban,
  bic = "",
  email
) => {
  try {
    const response = await bankAccountApi.post("/bank-details", {
      station_id: id,
      account_holder_name: name,
      iban: iban,
      bic: bic,
      email: email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
