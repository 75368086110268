import React from "react";
import { Button } from "primereact/button";

const PaginationControls = ({
  currentYear,
  currentMonth,
  goToPreviousMonth,
  goToNextMonth,
  isCurrentMonth,
  loading,
}) => {
  return (
    <div className="pagination-controls">
      <Button
        label="Previous"
        icon="pi pi-angle-left"
        iconPos="left"
        onClick={goToPreviousMonth}
        className="pagination-button"
        disabled={loading}
      />
      <span className="pagination-text">
        {new Date(currentYear, currentMonth - 1).toLocaleString("default", {
          month: "long",
          year: "numeric",
        })}
      </span>
      <Button
        label="Next"
        icon="pi pi-angle-right"
        iconPos="right"
        onClick={goToNextMonth}
        disabled={isCurrentMonth}
        className="pagination-button"
      />
    </div>
  );
};

export default PaginationControls;
