import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

import "./StickyActions.css";

export default function StickyActions({ stationId }) {
  const navigate = useNavigate();

  return (
    <div className="sticky-actions">
      <Button
        label="Add Owners"
        icon="pi pi-user-plus"
        className="p-button-outlined"
        onClick={() => navigate(`/add-owner/${stationId}`)}
      />
      <Button
        label="Manage QR Code"
        icon="pi pi-qrcode"
        className="p-button-outlined"
        onClick={() => navigate(`/qrcode-managment/${stationId}`)}
      />
      <Button
        label="Bank Account"
        icon="pi pi-wallet"
        className="p-button-outlined"
        onClick={() => navigate(`/add-bank-account-details/${stationId}`)}
      />
      <Button
        label="Transactions"
        icon="pi pi-calendar-clock"
        className="p-button-outlined"
        onClick={() => navigate(`/transactions/${stationId}`)}
      />
    </div>
  );
}
