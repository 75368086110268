import React, { useEffect, useRef } from "react";
import { useLoadScript } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const CustomGoogleMap = ({ center }) => {
  console.log(center);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    version: "weekly",
  });

  const mapRef = useRef(null);

  useEffect(() => {
    if (isLoaded) {
      const initMap = async () => {
        const { AdvancedMarkerElement } =
          await window.google.maps.importLibrary("marker");
        const map = new window.google.maps.Map(mapRef.current, {
          center: center,
          zoom: 12,
          mapId: process.env.REACT_APP_MAP_ID,
        });

        const marker = new AdvancedMarkerElement({
          map,
          position: center,
          title: `Advanced Marker at ${center.lat}, ${center.lng}`,
        });

        map.addListener("zoom_changed", () => {
          const zoom = map.getZoom();
          if (zoom < 12) {
            marker.map = null;
          } else {
            marker.map = map;
          }
        });
      };

      initMap();
    }
  }, [isLoaded, center]);

  if (!isLoaded) return <div>Loading...</div>;

  return <div ref={mapRef} style={mapContainerStyle}></div>;
};

export default CustomGoogleMap;
